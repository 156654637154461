import Axios from "axios";
import { getRegionalizedUrl, MANAGE_API } from "@mc/common/lib/url-utils";
import {
    encodeUrlParams
} from "@mc/common/lib/url-params";

export const validateSSOToken = (token) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/validate-sso',
        data: encodeUrlParams({
            v: 1,
            token: token
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const validateSSOSession = (session) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/validate-sso-session',
        data: encodeUrlParams({
            session: session
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const validateStoredSession = (session, email) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/validate_session',
        data: encodeUrlParams({
            user_email: email,
            session: session,
            admin: true,
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}