import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import React from "react"
import { AuthenticationContext, UPDATE_AUTH, UPDATE_CUSTOMER_INFO } from "../App/AuthenticationContext"
import { isLoggedIn, LOCAL_STORAGE_SESSION_KEY } from "../utils/auth"
import { validateSSOSession, validateStoredSession } from "../api/authentication"
import { isBrowser } from "@mc/common/lib/auth"
import { getCustomerInfo } from "../api/customer";

const logo = require("@mc/common/images/mimecast-white-logo.png")

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100vh'
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh',
  }
}))

const Index = ({ location }) => {
  const classes = useStyles()
  const [showSignInLink, setShowSignInLink] = React.useState(false)
  const [authState, authDispatch] = React.useContext(AuthenticationContext)

  const setSession = (responseData) => {
    authDispatch({
      type: UPDATE_AUTH,
      payload: {
        session: responseData.session,
        email: responseData.email,
        isDomainAdmin: responseData.is_domain_admin,
        isMimeOSCustomer: responseData.is_mime_os_customer,
        isImpersonating: responseData.is_impersonating
      }
    })
    if (isBrowser()) {
      localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, JSON.stringify({ session: responseData.session, email: responseData.email }))
    }
  }

  const validateToken = (session) => {
    validateSSOSession(session).then(
      response => {
        setSession(response.data)
      },
      error => {
        console.log(error)
        setShowSignInLink(true)
      })
  }

  const validateExistingSession = (session, email) => {
    validateStoredSession(session, email).then(
      response => {
        if (response.data.status === 'success') {
          setSession(response.data)
        }
        else {
          localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY)
          setShowSignInLink(true)
        }
      },
      error => {
        console.log(error)
        setShowSignInLink(true)
      })

  }

  const attemptSignIn = () => {
    const paramString = location.hash ? location.hash.split('?')[1] : ''
    const params = new URLSearchParams(paramString)
    const existingSessionString = isBrowser() ? localStorage.getItem(LOCAL_STORAGE_SESSION_KEY) : null
    if (params.has('session')) {
      validateToken(params.get('session'))
    }
    else if (existingSessionString) {
      const existingSessionObject = JSON.parse(existingSessionString)
      if (existingSessionObject && existingSessionObject.session && existingSessionObject.email) {
        validateExistingSession(existingSessionObject.session, existingSessionObject.email)
      }
    }
    else {
      setShowSignInLink(true)
    }

    getCustomerInfo(authState).then(result =>{
      authDispatch({
        type: UPDATE_CUSTOMER_INFO,
        payload: {
          customer_id: result.data.customer.customer_id,
          global_customer_id: result.data.customer.global_customer_id,
          computed_users: parseInt(result.data.customer.computed_users),
          licensed_users: parseInt(result.data.customer.licensed_users),
          server_type: result.data.customer.server_type,
        }
      })
    })
  }

  React.useEffect(attemptSignIn, [authState])

  if (isLoggedIn(authState) && authState.customerInfo.customer_id) {
    if (isBrowser()) {
      navigate(`/summary-page`)
    }
    return null
  }

  return (
    <div className={classes.root}>
      <Box className={classes.logoBox}>
        <img
          src={logo}
          alt=""
          style={{
            width: '300px',
            paddingBottom: 30
          }}
        />
        {showSignInLink ?
          <Typography variant='body2' color='secondary'>
            Your session has expired.
            </Typography>
          :
          <CircularProgress color="secondary" />
        }
      </Box>
    </div>
  )

}

export default Index